<template>
  <div
    id="container"
    class="flex flex-col box-border items-center custom-color"
    style="scroll-behavior: smooth;height:100vh;"
  >
    <!-- 首页大图 -->
    <!-- <div class="absolute top-0" style="z-index: -100">
      <img src="../src/assets/bg-empty.png" />
    </div> -->
    <NavBar @scrollChange="scrollChange"></NavBar>
    <!-- 主体内容 -->
    
    <div class="h-20 mt-10 fixed text-gray-500 text-sm" style="bottom:0px;">
    <footer>
      <a href="https://beian.miit.gov.cn/)">京ICP备2023011647号-5</a>
    </footer></div>
    <!-- 回到顶部的浮窗 -->
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data() {
    return {
      processData: [
        {
          width: '300',
          // vue在组件中创建组件，子组件无法引用图片解决方法。需加require引入
          img: require('../src/assets/process/mind.png'),
          title: '需求梳理、制定方案',
          content: '专业产品经理帮助您梳理项目需求，输出导图、原型图方案',
        },
        {
          width: '300',
          // vue在组件中创建组件，子组件无法引用图片解决方法。需加require引入
          img: require('../src/assets/process/prototype.png'),
          title: 'UI图绘制',
          content:
            '针对不同行业，不同风格，由专业的UI设计师为您设计产品界面，满意为止',
        },
        {
          width: '300',
          // vue在组件中创建组件，子组件无法引用图片解决方法。需加require引入
          img: require('../src/assets/process/development.png'),
          title: '程序开发',
          content: '经验丰富的开发团队，为您提供高质量的程序开发服务',
        },
        {
          width: '300',
          // vue在组件中创建组件，子组件无法引用图片解决方法。需加require引入
          img: require('../src/assets/process/test.png'),
          title: '产品测试',
          content: '专业产品测试团队，为您提供高质量的产品测试服务',
        },
        {
          width: '300',
          // vue在组件中创建组件，子组件无法引用图片解决方法。需加require引入
          img: require('../src/assets/process/deal.png'),
          title: '交付项目',
          content:
            '按时高质量的交付项目，协助客户注册，审核，认证，上线部署等流程',
        },
        {
          width: '300',
          // vue在组件中创建组件，子组件无法引用图片解决方法。需加require引入
          img: require('../src/assets/process/maintenance.png'),
          title: '售后维护',
          content: '交付不是结束，在售后维护期内，我们仍然提供专业的售后维护',
        },
      ],
      canDoList: [
        {
          title: '微信开发',
          iconName: 'icon-icon',
          content: ['小程序开发', '服务号开发', '订阅号开发', '微信网页开发'],
        },
        {
          title: '网站开发',
          iconName: 'icon-wangzhan',
          content: ['企业官网', '门户网站', '商城网站', '论坛网站', '定制网站'],
        },
        {
          title: '移动端开发',
          iconName: 'icon-_yidongkaifa',
          content: ['企业官网', '门户网站', '商城网站', '论坛网站', '定制网站'],
        },
        {
          title: '桌面端开发',
          iconName: 'icon-windows',
          content: ['办公管理', '财务管理', '数据分析', '营销工具', '定制工具'],
        },
        {
          title: 'APP开发',
          iconName: 'icon-app3',
          content: ['商城APP', '餐饮APP', '同城APP', '社交APP', '定制APP'],
        },
      ],
    }
  },
  methods: {
    gotoTop() {
      document
        .getElementById('container')
        .scrollIntoView({ behavior: 'smooth' })
    },
    scrollChange(idName) {
      document.getElementById(idName).scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>

<style>
@import './common/tailwind.css';
@import './common/iconfont/iconfont.css';

.phone-animation {
  animation: phoneAnimation 5s infinite;
  animation-timing-function: linear;
}

.pad-animation {
  animation: padAnimation 5s infinite;
  animation-timing-function: linear;
}
@keyframes phoneAnimation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-50px, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes padAnimation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(50px, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
